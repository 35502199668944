.full-width {
  width: 100%;
}

.btn-border-black {
  border: 1px solid black;
  border-radius: 25px;
  /* align text center */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  /* give hover efect */
}

.btn-radius {
  border-radius: 25px !important;
}

/* give hover effect on btn-border-black */
.btn-border-black:hover {
  border: 2px solid black;
}

.txt-bold {
  font-weight: bold !important;
}

.txt-center {
  text-align: center;
}

.flex-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-items-row {
  display: flex;
  flex-direction: row;
}

.flex-items-column {
  display: flex;
  flex-direction: column;
}

.bold-text {
  font-weight: bold !important;
}

.flex-items-space-between {
  display: flex;
  justify-content: space-between;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  box-sizing: border-box;
}


.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}


.section-tag {
  font-weight: bold !important;
}

.section-title {
  margin-top: 180px !important;
}



/* give fontsize on small devices section-tag */
@media (max-width: 768px) {
  .section-tag {
    font-size: 1.9rem !important;
  }

  .section-title {
    font-size: 0.5rem !important;
    margin-top: 140px !important;
  }
}
